'use client';

import { Box, Flex, Link, Text } from '@medsimples/design-system/src/chakra';
import { Button } from '@medsimples/design-system/src/components';
import AntecipameImg from '@public/antecipame.png';
import NextLink from 'next/link';
import { useDesignTokens } from './providers/design_tokens_provider';
import Image from 'next/image';

export default function AgenciaLandpage() {
  const tokens = useDesignTokens();

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      h='100%'
    >
      <Box textAlign='center' marginBottom={5}>
        <Text fontSize='lg'>
          Opere os próprios projetos de influência com mais autonomia
          <Text
            as='span'
            fontSize='2xl'
            fontWeight='700'
            color={tokens.text.highlight.color}
          />
        </Text>
      </Box>

      <Box textAlign='center' marginBottom={4}>
        <Image src={AntecipameImg} alt='antecipa.me image' />
      </Box>

      <Box textAlign='center' marginBottom={7} marginTop={4}>
        <Text fontSize='md'>
          Cadastre a empresa prestadora de serviços em saúde na qual é sócio!
        </Text>
      </Box>

      <Button
        colorScheme={tokens.button.primary.scheme}
        marginBottom={3}
        data-testid='button'
        as={NextLink}
        href={'/welcome'}
      >
        Vamos lá!
      </Button>

      <Box textAlign='center' marginBottom={10}>
        <Text fontSize={8}>
          * Ao clicar em "vamos lá" você concorda com os{' '}
          <Link
            target='_blank'
            href='https://static.medsimples.dev/medsimples_privacy_policy.pdf'
          >
            <Text as='span' color={tokens.text.primary.color} fontSize={8}>
              termos de privacidade
            </Text>
          </Link>{' '}
        </Text>
      </Box>
    </Flex>
  );
}
