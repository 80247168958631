'use client'

import MedSimples from '@/components/TenantName/MedSimples';
import { Box, Flex, Link, Text } from '@medsimples/design-system/src/chakra';
import { Button } from '@medsimples/design-system/src/components';
import Brazil from '@public/icons/brazil/brazil';
import NextLink from 'next/link';
import { useDesignTokens } from './providers/design_tokens_provider';

export default function MedsimplesLandpage() {
  const tokens = useDesignTokens();

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      h='100%'
    >
      <Box textAlign='center' marginBottom={5}>
        <Text fontSize='lg'>
          Atua nestas regiões e gostaria de cadastrar sua empresa de serviços em
          saúde?
          <Text
            as='span'
            fontSize='2xl'
            fontWeight='700'
            color={tokens.text.highlight.color}
          />
        </Text>
      </Box>

      <Box textAlign='center' marginBottom={4}>
        <Brazil
          color={tokens.text.grayLighter.color}
          width='100%'
          height='100%'
        />
      </Box>

      <Box textAlign='center' marginBottom={7} marginTop={4}>
        <Text fontSize='md'>
          Cadastre a empresa prestadora de serviços em saúde na qual é sócio!
        </Text>
      </Box>

      <Button
        colorScheme={tokens.button.primary.scheme}
        marginBottom={3}
        data-testid='button'
        as={NextLink}
        href={'/welcome'}
      >
        Vamos lá!
      </Button>

      <Box textAlign='center' marginBottom={10}>
        <Text fontSize={8}>
          * Ao clicar em "vamos lá" você concorda com os{' '}
          <Link
            target='_blank'
            href='https://static.medsimples.dev/medsimples_privacy_policy.pdf'
          >
            <Text as='span' color={tokens.text.primary.color} fontSize={8}>
              termos de privacidade
            </Text>
          </Link>{' '}
          <MedSimples />.
        </Text>
      </Box>
    </Flex>
  );
}
